<template>
  <div class="content">
    <!-- v-if="isAdmin()" -->
    <!-- active-value=1 inactive-value=0 -->
    <div class="card-box">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" @change="func.OperatorChange" :clearable="true"
            v-model="formData.valOperator" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
      </div>
      <div class="line"></div>

      <!-- <div class="switch_title">小程序功能开关</div> -->
      <div class="switch_box_left1">
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsSms ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(0, 'IsSms')">
            <img class="card_img" src="../../assets/img/control1.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsSms" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div>{{ $t('agentsSwitch.SMS_verification') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsIdVerify ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(1, 'IsIdVerify')">
            <img class="card_img" src="../../assets/img/control2.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsIdVerify" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.authentication') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg"
            :class="formData.switchData.IsReturnClear ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(2, 'IsReturnClear')">
            <img class="card_img" src="../../assets/img/control3.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsReturnClear" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.clearance_package') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsCoupon ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(3, 'IsCoupon')">
            <img class="card_img" src="../../assets/img/control4.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsCoupon" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.coupon_function') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsStage ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(4, 'IsStage')">
            <img class="card_img" src="../../assets/img/control5.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsStage" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.temporary_storage') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsPull ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(5, 'IsPull')">
            <img class="card_img" src="../../assets/img/control6.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsPull" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.new_feature') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg"
            :class="formData.switchData.IsRentAndCommbo ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(6, 'IsRentAndCommbo')">
            <img class="card_img" src="../../assets/img/control7.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsRentAndCommbo" :active-text="$t('agentsSwitch.Enable')"
          :inactive-text="$t('agentsSwitch.Disable')" inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.package_bundled') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsTTS ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(7, 'IsTTS')">
            <img class="card_img" src="../../assets/img/control8.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsTTS" :active-text="$t('agentsSwitch.Enable')"  :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.voice_broadcasting') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsLateFee ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(8, 'IsLateFee')">
            <img class="card_img" src="../../assets/img/control9.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsLateFee" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.late_payment_deduction') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsSameCombo ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(9, 'IsSameCombo')">
            <img class="card_img" src="../../assets/img/control10.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsSameCombo" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">{{ $t('agentsSwitch.first_package') }}</div>
        </div>
        <div class="card">
          <div class="card_img_bg" :class="formData.switchData.IsHideCombo ? 'card_img_trigger' : 'card_img_untrigger'"
            @click="func.card_opt(10, 'IsHideCombo)')">
            <img class="card_img" src="../../assets/img/control11.png">
          </div>
          <el-switch class="card_switch" v-model="formData.switchData.IsHideCombo" :active-text="$t('agentsSwitch.Enable')" :inactive-text="$t('agentsSwitch.Disable')"
            inline-prompt />
          <div class="card_txt">输入码定制套餐</div>
        </div>
      </div>

      <!-- <div class="switch_box_left">
        <div class="switch_box_switch">
          <span>短信验证：</span><el-switch v-model="formData.switchData.IsSms" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div>
        <div class="switch_box_switch">
          <span>身份验证：</span><el-switch v-model="formData.switchData.IsIdVerify" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div>
        <div class="switch_box_switch">
          <span>退租清除套餐：</span><el-switch v-model="formData.switchData.IsReturnClear" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div> -->
      <!-- <div class="switch_box_switch"><span>保存电池信息：</span><el-switch v-model="formData.switchData.IsBatInfoSave" active-text="启用"  inactive-text="禁用" inline-prompt /></div> -->
      <!-- <div class="switch_box_switch">
          <span>优惠券功能：</span><el-switch v-model="formData.switchData.IsCoupon" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div>
        <div class="switch_box_switch">
          <span>暂存功能：</span><el-switch v-model="formData.switchData.IsStage" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div>
        <div class="switch_box_switch">
          <span>拉新功能：</span><el-switch v-model="formData.switchData.IsPull" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div> -->
      <!-- <div class="switch_box_switch"><span>独立商户：</span><el-switch v-model="formData.switchData.IsMch" active-text="启用"  inactive-text="禁用" inline-prompt /></div> -->
      <!-- <div class="switch_box_switch">
          <span>租换电套餐捆绑：</span><el-switch v-model="formData.switchData.IsRentAndCommbo" active-text="启用"
            inactive-text="禁用" inline-prompt />
        </div>
        <div class="switch_box_switch">
          <span>语音播报功能：</span><el-switch v-model="formData.switchData.IsTTS" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div>
        <div class="switch_box_switch">
          <span>逾期扣费功能：</span><el-switch v-model="formData.switchData.IsLateFee" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div>
        <div class="switch_box_switch">
          <span>只能购买首次套餐：</span><el-switch v-model="formData.switchData.IsSameCombo" active-text="启用" inactive-text="禁用"
            inline-prompt />
        </div>
      </div> --> 
      <el-button class="switch_box_btn btn-edit" type="primary" size="big" @click="func.save">{{$t('agentsSwitch.save_settings')}}</el-button>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from "vue";
import { updateOperators, operatorList, cascade_options_direct } from "@/data/default";
import { toDay } from "@/common/func";
import axios from "axios";
import { ElMessage } from "element-plus";
import i18n from "@/data/i18n/index";
import { isOperator, isAdmin } from "@/data/token";

export default {
  name: "ExpensesAgents",
  setup() {
    const t = i18n.global.t;
    const render = {
      operatorList,
      cascade_options_direct
    };
    const formData = reactive({
      valOperator: "",
      switchData: {
        IsSms: false,
        IsIdVerify: false,
        IsReturnClear: false,
        // IsBatInfoSave:0,
        IsCoupon: false,
        IsStage: false,
        IsPull: false,
        // IsMch:0,
        IsRentAndCommbo: false,
        IsTTS: false,
        IsLateFee: false,
        IsSameCombo: false,
        IsHideCombo:false
      },
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
    });
    const func = {
      async save() {
        let primary;
        if (!formData.valOperator) {
          ElMessage({
            type: "error",
            message: "请选择运营商",
          });
          return;
        }
        primary = { AgentCode: formData.valOperator, ...formData.switchData };
        // if (isAdmin()) {

        // } else {
        //   primary = { ...formData.switchData };
        // }

        console.log(primary, "提交的参数");
        let res = await axios.post(`/admin/agent/editmenu`, primary);
        console.log(res);
        if (res == "操作成功") {
          ElMessage({
            type: "success",
            message: t("g.success"),
          });
        } else {
          ElMessage({
            type: "error",
            message: t("err_code.20005"),
          });
        }
      },
      card_opt(i, name) {
        let list = document.getElementsByClassName('card_img_bg')
        list[i].classList.add('anim_shrink')
        setTimeout(() => {
          formData.switchData[name] = !formData.switchData[name]
          list[i].classList.remove('anim_shrink')
        }, 700)

      },
      async OperatorChange() {
        const params = {};
        // if (isAdmin()) {}
        params.AgentCode = formData.valOperator;

        try {
          let res = await axios.get("/admin/agent/getmenu", { params });
          formData.switchData = res.List[0];
          console.log(res, formData.valOperator, params);
        } catch (e) {
          console.log("loadData", e);
        }
      },
    };
    onMounted(() => {
      //if (!isAdmin()) {
      func.OperatorChange();
      //}
    });
    return {
      func,
      render,
      formData,
      isOperator,
      isAdmin,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

@media screen and (max-width: 800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}

.switch_box_switch {
  width: 25%;
  margin-bottom: 30px;
}

.switch_title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: left;
}

.switch_box_left {
  display: flex;
  flex-wrap: wrap;
}

.switch_box_switch span {
  display: inline-block;
  width: 130px;
  text-align: left;
}

.switch_box_btn {
  margin: 0 auto;
  margin-top: 50px;
}

.card {
  width: 128px;
  text-align: center;
  background-color: #e7f2f9;
  border-radius: 10px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-self: center;
}

.card_img_bg {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #2765a3;
  border-radius: 50%;
  margin: 0 auto;
  // animation: shrink 2s infinite;
}

.card_img_trigger {
  background-color: rgb(100, 144, 239);
}

.card_switch {
  margin-top: 15px;
  --el-switch-on-color: rgb(100, 144, 239);
}

.card_img_untrigger {
  background-color: #7d7d7d;
}

.card_img {
  width: 30px;
  height: auto;

}

.card_txt {
  // margin-top: 15px;
  width: 100%;
}

.switch_box_left1 {
  display: grid;
  // flex-wrap: wrap;
  // justify-content: space-around;
  grid-template-columns: repeat(6, 1fr);
  gap: 50px 50px;
  padding: 0 80px;
  
}

.anim_shrink {
  animation: shrink 0.7s linear;
}

// .anim_shrink1{
//   animation: shrink1 1s linear;
// }



@keyframes shrink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

// @keyframes shrink1 {
//   0% {
//     transform: scale(1);
//     background-color: #adadad;
//   }
//   50% {
//     transform: scale(0.9);
//     background-color: #adadad;
//   }
//   100%{
//     transform: scale(1);
//     background-color: #2765a3;
//   }
// }
// @keyframes spin {
//   0% { transform: rotateY(0deg); }
//   50% { transform: rotateY(180deg); }
//   100%{transform: rotateY(0deg);}
// }
// .card_img:hover {
//   animation: spin 2s  linear; 
// }</style>
