export default {
  test:"测试",
  appname:"蒲公英管理系统",
  tips:'提示',
  confirm:"确定",
  cancel:"取消",
  err_code:{
    201:"登录失败",
    523:"用户未注册",
    530:"请登录",
    531:"请先购买或续约套餐",
    580:"存在未支付充电订单，请先支付",
    581:"最多支持三个充电订单",
    700:"余额需要大于5元才可进行充电",
    20000:"系统错误",
    20001:"json转化错误",
    20002:"该用户尚未绑定电池",
    20003:"传入参数错误",
    20004:"增加数据失败",
    20005:"修改失败",
    20006:"当前模块下面还有菜单或者操作，无法删除",
    20007:"请传入id",
    20008:"请填写正确的商户号",
    20009:"该代理商未绑定后台用户",
    20010:"请选择上传文件",
    20011:"上传文件不合法",
    20012:"创建目录失败",
    20013:"电池创建失败",
    20014:"充电柜创建失败",
    20015:"数据不存在",
    20016:"添加失败，已存在该类型套餐",
    20017:"创建失败",
    20018:"找不到该套餐",
    20019:"非法请求",
    20020:"用户名或者密码长度不合法",
    20021:"数据已存在",
    20022:"密码长度不合法,密码长度不能小于6位",
    20023:"特殊角色不可删除",
    20024:"该用户角色并未绑定权限列表",
    20025:"图片后缀名不合法",
    20026:"该用户尚未绑定电池",
    20027:"用户尚未购买套餐",
    20028:"无法使用该运营商设备",
    20029:"设备告警，暂无法使用",
    20030:"设备禁用中，暂不可用",
    20031:"套餐不存在",
    20032:"余额不足",
    20033:"押金未设置",
    20034:"扣除用户余额失败",
    20035:"保存用户消费日志失败",
    20036:"已够买过套餐，不可购买不同类型套餐",
    20037:"不存在该电池押金订单",
    20038:"微信支付初始化失败",
    20039:"不存在该用户有效电池",
    20040:"设备暂不可用，无法结束充电",
    20041:"非维护人员不可进行该操作",
    20042:"微信支付回调失败",
    20043:"时间没有到期，无法删除！",
  },
  chart:{
    keys:{
      "离线":"离线",
      "缺电":"缺电",
      "锁仓":"锁仓",
      "充满":"充满",
      "闲置":"闲置",
      "充电中":"充电中",
      "故障停用":"故障停用",
    },
    turnover_types:{
      wallet_charge:"钱包充值",
      wallet_consume:"钱包消费",
      deposit:"押金",
      setmenu:"换电套餐",
    },
    max_value:"最大值",
    today:"今天",
    yesterday:"昨天",
    site_user_trend:"网点用户趋势丨扫码量",
    num:"数量",
    trends:{
      today:'今日走势丨实时',
      turnover:"流水趋势",
      ebox:"换电柜订单周期趋势",
      cbox:"充电柜订单周期趋势",
      pile:"充电桩订单周期趋势",
      consume:"消费趋势",
    },
    order_ratio:"订单占比",
    order_types:{
      rent:"租电",
      return:"还电",
      exchange:"换电",
      cbox:"充电柜",
      pile:"充电桩",
      charge:"充值",
      setmenu:"换电套餐",
    },
    order_status:"订单状态|实时",
    order_statuses:{
      doing:"进行中",
      error:"异常",
      finished:"已结束",
    },
    devs_status:{
      ebox:"换电柜设备状态 | 实时",
      cbox:"充电柜设备状态 | 实时",
      pile:"充电桩设备状态 | 实时",
      cpbox:"混充设备状态 | 实时",
    },
    dev_charge_status:{
      ebox:'换电柜充电状态 | 实时',
      cbox:"充电柜充电状态 | 实时",
      pile:"充电桩充电状态 | 实时",
      cpbox:"混充设备充电状态 | 实时",
    },
    unit_times:"次",
    devs_rank:{
      err:"高频故障排行 | Top 30",
      offline:"高频断网排行 | Top 30",
      locked:"高频锁仓排行 | Top 30",
      open:"开仓异常排行 | Top 30",
      charge:"充电异常排行 | Top 30",
      frequence_high: "高频使用设备 | Top 30",
      frequence_low:"低频使用设备 | Top 30",
      lack:"缺电柜组 | Top 30",
    },
  },
  market:{
    color:{
      explain:"颜色代表",
      types:{
        red:"红",
        orange:"橙",
        blue:"蓝",
      },
      means:{
        red:"高频",
        orange:"中频",
        blue:"低频",
      }
    }
  },
  menu:{
    home:"主页",
    big_screen:"大屏展示",
    big_data:"大数据",
    decision:{
      analyze:"数据分析",
      hot:"热力分析",
      market:"市场分析",
      operation:"运营分析",
      properties:"资产分析",
      order:"订单分析",
      user:"用户分析",
      statistics:"资产收益",
    },
    distribution_site:"网点分布",
    distribution_battery:"电池分布",
    finance:"账务",
    expenses_center:"费用中心",
    set_menu_manage:"套餐设置",
    ExpensesBeOverdue:'逾期管理',
    bill_manage:"账单管理",
    turnover_record:"流水记录",
    settle:"清分结算",
    refund:"退款审核",
    badbill:"坏账记录",
    workOrder:"工单管理",
    devManage:"设备管理",
    operationManage:"运营管理",
    workOrderList:"工单列表",
    workOrderSet:"工单设置",
    refund_record:"退款记录",
    overdue:"逾期记录",
    orders:{
      manage:"订单管理",
      rent:"租电订单",
      exchange:"换电记录",
      return:"还电记录",
      cbox:"充电柜订单",
      pile:"二轮充电桩订单",
      carPile:"汽车充电桩订单",
      setmenu:"套餐订单",
      recharge:"充值订单",
      offline:"购卡订单",
      temporary_record:"暂存记录",
      import_record:"导入记录",
    },
    operation:"运营",
    member:"会员中心",
    profile:"个人资料",
    member_list:"用户资料",
    agent_list:"代理商列表",
    operator_list:"运营商管理",
    SecondTierAgent:"一级运营商",
    ThirdTierAgent:"二级运营商",
    FourthTierAgent:"三级运营商",
    agents_switch:'功能控制',
    after_sale_service:"售后服务",
    open_solot_record:"开仓信息",
    open_solot:"开仓记录",
    electricity_stat:"电费统计",
    alert_record:"报警信息",
    activity_manage:"活动管理",
    recruit_manage:"拉新管理",
    discount_coupon:'优惠券',
    award_manage:"领取记录",
    recruit_new:"拉新记录",
    announcement_list:"公告栏",
    withdraw:"提现管理",
    restriction_rule:"规则限制",
    feedback:"用户反馈",
    sys:"系统",
    log_info:"日志信息",
    log_sys:"系统日志",
    log_api:"设备日志",
    msg_log:"消息日志",
    properties:"资产",
    billStrategy:"计费策略",
    siteMange:"站点管理",
    box_manage:"电柜管理",
    ebox_list:"换电柜列表",
    cbox_list:"充电柜列表",
    cpbox_list:"混充电柜列表",
    pile_list:"二轮充电桩列表",
    car_pile_list:"汽车充电桩列表",
    card_list:"实卡列表",
    pile_manage:"桩充管理",
    battery_manage:"电池管理",
    card_manage:"刷卡管理",
    battery_list:"电池列表",
    rights_manage:"权限管理",
    rights_set:"权限配置",
    managers:"管理员管理",
    role_manage:"角色管理",
  },
  common:{
    popup_titles:{
      t0:"最新用户反馈",
      t1:"重要告警信息",
      t2:"最新订单",
    },
    no_content:"暂无内容",
    rights:"© 2021深圳市麦迪瑞科技有限公司 . 版权所有",
    days:"天",
    hours:"小时",
    minutes:"分",
    seconds:"秒",
    not_support:"系统不支持",
    coord_errs:{
      t0:"您拒绝共享位置信息",
      t1:"获取不到位置信息",
      t2:"获取位置信息超时",
    },
    all:"全部",
    out_of_sync:"未同步",
    dates:{
      last_week:"最近一周",
      last_month:"最近一个月",
      last_two_month:"最近两个月",
      last_quarter:"最近三个月",
      last_six_month:"最近六个月",
      last_year:"最近一年",
      future_week:"未来一周",
      future_month:"未来一个月",
      future_quarter:"未来三个月",
    },
  },
  g:{
    loading:"加载中...",
    QRCode:"二维码",
    sure_delete:"确认要删除吗?",
    to:"至",
    start_date:"开始日期",
    end_date:"结束日期",
    sum:"共",
    currency:'元',
    unit_num:"共",
    per_page:"每页",
    see:"查看",
    total:"合计",
    minute:"分钟",
    operation:"操作",
    search:"查询",
    clear:'清除',
    abnormal:'异常',
    year:'年月',
    month:'月',
    btn_confirm:"确 定",
    btn_cancel:"取 消",
    condition:"查询条件",
    create_at:"创建时间",
    update_at:"更新时间",
    battery_update_at:"电池更新时间",
    name:"名称",
    operator:"运营商",
    agent_code:"代理商",//运营商
    choose:"请选择",
    success:"操作成功",
    upload_failed:"上传失败",
    please_input:"请输入",
    residue_degree:'剩余次数',
    residue_days:'剩余天数',
    yes:"是",
    open:"打开",
    close:"关闭",
    no:"否",
    fei:'非',
    null:'无',
    detail:"详情",
    edit:"编辑",
    delete:"删除",
    ms:"后台管理系统",
    battery_status:"电池状态",
    stage:'暂存',
    stage_day:'累计暂存天数',
    staging_day:'暂存中天数',
    stage_month_num:'暂存次数',
    operator_ownership:'运营商归属',
    export:'导出',
    Success:"成功",
    Failed:"失败",
    total:'总计',
    date:'日期',
    state:'状态',
    added:'新增',
    yuan:'元',
    day:'天',
    name:'姓名',
    enter_name:'请输入姓名',
  },
  home:{
    onlineCab:'在线机柜',
    offlineCab:'离线机柜',
    onlineBat:'在线电池',
    offlineBat:'离线电池',
    dataStat:'数据统计',
    commonFunctions:'常用功能',
    bill:'流水记录',
    more:'更多',
    sevenDay:'近7天',
    oneMonth:'近一月',
    income:'收益金额',
    newUser:'新租用户',
    batteryReplace:'换电数量',
    comboNum:'套餐数量',
    battayReturn:'还电数量',
    userManage:'用户管理',
    cabManage:'机柜管理',
    coupons:'优惠券',
    battayReturnList:'换电记录',
    newRentList:'租电记录',
    feedback:'用户反馈',
    refundReview:'退款审核',
    combo:'套餐',
    topUp:'充值',
    pay:'消费',
    charge:'充电',
    Refund:'退款',
    deposit:'押金',
    fine:'罚金',
    overduefines:'逾期罚款',
  },
  profile:{
    roleDes:'角色描述',
    lastLoginTime:'最近登录时间',
    curLoginTime:'当前登录时间',
    personalDetails:'个人详情',
  },
  energy:{
    num:'当月电量',
    price:'当月电价',
    fee:'当月费用',
    first:'月始电量记录',
    last:'月末电量记录'
  },
  rights:{
    please_choose_deparment:"请选择部门",
    is_mch:"是否为直连商户",
    name:"名称",
    description:"描述",
    username:"账号",
    role:"角色",
    password:"密码",
    ph_psw:"不修改则不填",
    department:"所属部门",
    module_parent:"上级模块",
    module_name:"模块名称",
    module_address:"模块地址",
    module_top:"---顶级模块---",
    rights:"权限",
    agent:"代理商",
    site_manager:"场站管理员",
    dev_ids:"设备id",
    dev_ids_tips:"多个用英文逗号分隔",
    effective_time:"有效日期",
    choose_time:"选择到期日期",
    belonging_role:"所属角色",
    role_attribution:"角色归属",
    superior_operator:"上级运营商",
    First_operatorname:"一级运营商名称",
    opt_operator1:"请输入6个字以内一级运营商名称",
    weChat_account:"微信商户号",
    payment_weChat:"支付相关微信商户",
    weChat_password:"微信商户号密码",
    no_need:"不需要修改不填",
    top_operators:"顶级运营商",
    opt_operator2:"请选择归属的顶级运营商",
    first_operator:"一级运营商",
    opt_operator3:"请选择归属的一级运营商",
    second_operatorname:"二级运营商名称",
    opt_operator4:"请输入6个字以内的二级运营商名称",
    secondary_operator:"二级运营商",
    opt_operator5:"请选择归属的二级运营商",
    third_operatorname:"三级运营商名称",
    opt_operator6:"请输入6个字以内三级运营商名称",
  },
  order:{
    remark:"备注",
    unit_times:"次",
    unit_months:"个月",
    combo_type:"类型",
    end:"结束订单",
    input_end_reason:"请填写结束原因",
    name:"会员",
    no:"订单号",
    price:"金额",//这里应该翻译为money
    failed_reason:"失败原因",
    end_reason:"结束原因",
    slot_get:"取出仓门",
    power_level:"充电功率",
    slot_return:"归还仓门",
    site_name:"场站",
    pay_way:"支付方式",
    back_way:"退还方式",
    unpayed:"未支付",
    overdueDeduction:"逾期扣费",
    overdueDay:"逾期扣除天数",
    pilePosition:"桩位",
    combo:{
      type:'套餐类型',
      price:"套餐价格",
      actualAmount:"实付金额",
      cashDiscount:"现金抵扣",
      couponDiscount:"优惠券抵扣",
      RefundAmount:"退款金额",
    },
    ps:{
      //充电桩状态
      all:"全部",
      unresponsive:"设备未响应",
      charging:"充电中",
      full:"已充满",
      finished:"已完成",
      depositing:"寄存中",
      prepare_failed:"Prepare Failed",
      failed:"预充失败,待取电",
    },
    cs:{//充电柜状态
      all:"全部",
      waiting:"等待设备响应",
      charging:"充电中",
      full:"充满寄存中",
      finished:"已完成",
      depositing:"寄存付费中",
      prepare_failed:"预充失败",
      depositing_free:"充满免费寄存中",
      failed:"失败",
    },
    took_battery:{
      no:"取出电池编号",
      soc:"取出电池SOC",
    },
    moneys:{
      charge:"充电费用(元)",
      deposit:"寄存费用(元)",
    },
    PaymentStatus:{
      name:"支付状态",
    },
    carPile:{
      gunNumber:"枪号",
      initAmount:"初始金额",
      totalCost:"总费用",
      chargingCapacity:"充电电量",
      chargingTime:"充电时长",
      chargingCosts:"充电费用",
      serviceFees:"服务费用",
      InitSoc:"初始soc",
      endSoc:"结束soc",
      stopCode:"停止码",
      carVin:"汽车VIN",
      stopReason:"停止原因",
    },
    unlock:"解锁",
    carPileStatus:{
      starting:"启动中",
      Charging:"充电中",
      ChargingEnd:"充电结束",
      chargingRecordUpload:"充电记录上传",
    },
    rentStatus:'租电状态',
    event:'事件',
    ReturnBatteryVoltage:'归还电池电压',
    TakeOutBatteryVoltage:'取出电池电压',
    ReturnBatterySoc:'归还电池soc',
    deposit_duration:"寄存时长(分)",
    charge_duration:"充电时长(分)",
    slot_id:"仓位",
    times:{
      charge_start:"充电开始时间",
      charge_end:"充电结束时间",
      took_battery:"取走电池时间",
    },
    returned_battery_no:"归还电池编号",
    slot_battery_before:"开仓前电池信息",
    failed_reasons:{
      t1:"无可换电池",
      t2:"关闭空仓门超时",
      t3:"不够空仓",
      t4:"插入电池超时",
      t5:"柜机忙",
      t6:"设备不在线",
      t7:"插入电池确认失败",
      t8:"柜机超时",
    },
    pay_ways:{
      wechat:"微信支付",
      balance:"余额支付",
      cash:'现金支付',
      combo:'套餐支付',
      paypal:'paypal支付',
      alipay:'支付宝支付',
    },
    returned:"已还",
    imgs:{
      t0:"开空仓图",
      t1:"关空仓图",
      t2:"开电池仓图",
      t3:"关电池仓图",
    },
    inp:{
      name:"请输入会员昵称",
      order_no:"请输入订单号",
      device_no:"请输入设备号",
    },
  },
  member:{
    cur_month_cost:"当月消费金额",
    cur_month_order_amount:"当月订单数",
    month_card_expire:"月卡即将到期用户",
    expired_at:"有效期",
    expired_day:"次数有效期(天)",
    expire:"有效套餐到期",
    all_expire:"套餐到期",
    package_expired:"套餐已过期",
    num_expired:'次数到期',
    is_expire:"套餐生效",
    reg_at:"注册时间",
    agent_name:"名称",
    is_vip:"VIP用户",
    agent_name2:"运营商名称",
    agent_info:"运营商简介",
    IdNum:"身份证号码",
    IdCardimg:"身份证照片",
    IdTime:"身份证有效期",
    IdName:"用户姓名",
    wechat_rate:"微信费率(%)",
    mobile:"手机号码",
    email:"邮箱",
    remainingTime:"剩余时长",
    verifySMS:"是否验证短信",
    temporaryState:"暂存状态",
    temporaryStorageTime:"暂存中时长",
    activated:"已激活",
    cashYuan:"现金到账(元)",
    cashDay:"已到账(天)",
    invited:"已邀请",
    balanceAccount:"余额到账(元)",
    notReceived:"未到账(天)",
    totalInvitationAmount:"邀请总金额",
    clear_mobile:"重验手机",
    clear_mobile_success:"重新验证手机成功",
    clear_mobile_fail:"重新验证手机失败",
    confirm_clear_mobile:"确认重新验证手机号码",
    res_mobile_fail:"，清除后需要重新验证",
    status:"状态",
    recharge:"余额充值",
    rechargeAmount:"请输入充值金额：",
    is_release:"是否发布",
    user_status:'用户状态',
    statuses:{
      all:"全部",
      success:"成功",
      completed:"已完成",
      failed:"失败",
      charging:"充电中",
      waiting:"等待设备响应",
      returned:"已退还",
      unpay:"未支付",
      paid:"已支付",
    },
    order_status:{
      name:'套餐状态',
      all:'全部',
      rented:'在租',
      overdue:'逾期',
      new_rent:'新租',
      renew_lease:'续租',
      rent_out:'退租',
    },
    battery_status:'绑定电池',
    agent_code:"编号",
    badBatteryNum:'旧电池ID',
    goodBatteryNum:'新电池ID',
    unBindBatteryNum:'解绑电池ID',
    levelname:"等级名称",
    level:"等级",
    username:"账号",
    nickname:"会员名称",
    coupon_day:"优惠券(天)",
    coupon_money:"优惠券(元)",
    detail:"会员详情",
    mchid:"微信商户号",
    mchid2:"微信商户号(支付相关)",
    mchid_zfb:"支付宝商户号",
    mchid2__zfb:"支付宝商户号(支付相关)",
    password:"微信商户号密码(不需要修改成不填)",
    new_password:"新密码",
    new_password_ph:"请输入新密码",
    repassword:"重复密码",
    repassword_ph:"重复输入新密码",
    repassword_not_same:"两次密码不一致",
    modify_password:"修改密码",
    balance:"余额",
    avatar:"头像",
    bind_battery:"更换电池",
    more_actions:"更多操作",
    unbind_battery:"解绑电池",
    maintainer_set:"普通用户",
    level1_user:"一级运维账号",
    level2_user:"二级运维账号",
    level3_user:"三级运维账号",
    deductionDays:"扣除天数",
    maintainer_unset:"维护用户",
    freeuser_set:"收费用户",
    freeuser_unset:"免费用户",
    forbidden:"禁用",
    overdue:"逾期用户",
    bound_battery_no:"已绑定电池编号",
    battery_no:"电池编号",
    err_money:"错误的金额",
    input_battery_no:"请输入电池编号",
    bind_success:"绑定成功",
    unbind_success:"解绑成功",
  },
  log:{
    trigger_time:"触发时间",
    name_contain:"名称包含",
    address_contain:"地址包含",
    input_name:"请输入设备名称",
    input_address:"请输入设备地址",
    info:"日志信息",
    info_detail:"日志详情",
    type:"类型",
    types:{
      t0:"换电柜告警",
      t1:"换电柜故障",
      t2:"充电柜告警",
      t3:"充电柜故障",
      t4:"充电桩告警",
      t5:"充电桩故障",
    },
    detailed_logs:"日志详细",
    cabinet_disconnection:"机柜掉线",
    battery_disconnection:"电池掉线",
    door_malfunction:"仓门故障",
    message:"消息",
    maintain_warehouse:"维护开仓",
    battery_shortage:"电池变少",
    replace_battery:"换绑电池",
    empty_warehouse:"假空仓",
  },
  decision:{
    type:"类 型",
    cycle:"周 期",
    time:"时 间",
    poi:"地名",//地图poi
    stat_condition:"统计条件",
    input_name:"请输入名称",
    active_user:"活跃用户",
    able_user:"有效用户",
    rent_income:"租电收益",
    combo_income:"套餐收益",
    charge_income:"充值收益",
    all_income:"总收益",
    tips:{
      type:"请选择类型",
      status:"请选择状态",
      num:"请输入数量",
      price:"请输入价格",
      agent_code:"请选择运营商",
      correct_price:"请输入正确的价格",
      correct_num:"请输入正确的数量",
    },
    types:{
      distribution_device:"设备分布",
      distribution_member:"会员分布",
      distribution_order:"订单分布",
    },
    cycles:{
      by_year:"按年",
      by_month:"按月",
      by_day:"按日",
    },
  },
  expenses:{
    name:"套餐名称",
    type:"类型",
    num:"数量",
    num_explain:"数量(对应月数或者次数)",
    price:"价格",
    status:"状态",
    enable:"启用",
    disable:"禁用",
    combo_code:'套餐码',
    statuses:{
      all:"所有",
      enabled:"已启用",
      disabled:"已禁用",
    },
    types:{
      all:"所有",
      by_month:"按月",
      by_times:"按次",
      by_day:"按天",
    },
  },
  bill:{
    total_charge:"充值总额",
    total_consume:"消费总额",
    total_deposit:"押金总额",
    sure_refund:"确定要退款吗？",
    status:"状态",
    type:"类型",
    types:{
      all:"所有",
      rent:"租电订单",//账单管理 => 流水记录
      cbox:"电柜充电订单",
      ebox:"换电订单",
      pile:"充电桩订单",
      charge:"充值订单",
      combo:"套餐购买订单",
      return:"还电订单",
      deposit:"充电订单寄存费用",
    },
    logTypes:{
      consume:"消费",
      charge:"充值",
      deposit:"押金",
    },
    refund_types:{
      deposit:"押金",
      balance:"余额",
      bad_bill:"坏账"
    },
    user:"用户",
    name:"名称",
    nickname:"用户昵称",
    user_nickname:"用户昵称",
    operator:"操作人",
    opt_money:"操作金额",
    remark:"原因",
    record_add:"添加记录",
    total:"账单总额",
    user_id:"会员ID",
    no:"单号",
    money:"金额",
    refunded:"已退",
    refund:"退款",
    refund_money:"退款金额",
    refunded_money:"已退金额",
    bill_date:"账期",
    settle_count:"结算笔数",
    money_received:"实收总额",
    money_bad_bill:"坏账总额",
    settle_rate:"结算比例",
    money_settle:"结算金额",
    rs:{
      all:"所有",
      waiting:"待退款",
      refunding:"退款中",
      refunded:"已退款",
      refundfail:"退款失败",
    },
    ph:{
      negative_as_cost:"负数为扣款",
      user_id:"返回账单至会员账号,不填为略过",
      reason:"请填写原因"
    }
  },
  device:{
    day_order_num:"日单数",
    week_order_num:"周单数",
    month_order_num:"月单数",
    charge_rule:"收费标准",
    add:"添加",
    delete:"删除",
    power_gt:"当功率大于",
    great_than:"大于",
    the_price:"收费为",
    price_unit:"元/小时",
    latitude:"设备纬度",
    longitude:"设备经度",
    big_power_money:"超大功率充电费用",
    middle_power_money:"大功率充电费用",
    small_power_money:"普通功率充电费用",
    big_power_range:"超大功率范围",
    middle_power_range:"大功率范围",
    small_power_range:"普通功率范围",
    electric_money:"每0.1度价格",
    please_choose_status:"请选择状态",
    please_input_full_info:"请填写完整信息",
    sure_open_slot:"确认要开仓吗?",
    sure_restart_dev:"确认要重启设备吗?",
    cmd_sent:"命令已发送，请稍候",
    alert:"设备告警",
    site_name:"站点名称",
    life_time:"运行时间(秒)",
    update_time:"最后更新时间",
    temp:"温度",
    mac:"Mac地址",
    meas_power:"功率",
    electric_quantity:"电量",
    electric_price:"电量价格",
    coordinate:"设备坐标",
    img_dev:"设备图",
    img_site:"站点图",
    img_env:"街景图",
    slot_num_all:"总仓数",
    slot_num_empty:"空仓数",
    slot_num_bat:"电池仓数",
    slot_num_error:"异常仓数",
    slot_num_box:"电仓数",
    slot_num_pile:"电桩数",
    pile_num_all:"总桩数",
    pile_num_empty:"空桩数",
    pile_num_error:"异常桩数",
    open_all_slots:"开全仓",
    reset:"重启",
    close_heater:"关闭加热器",
    open_heater:"打开加热器",
    slot_battery:"电池仓",
    slot_empty:"空仓",
    slot_type:"类型",
    slot_warning:"告警",
    slot_status:"仓门状态",
    device_id:'电池ID',
    update_device_last:'最后更新电池ID',
    specs:"设备规格",
    voltage:"电压",
    current:"电流",
    slot_no:"仓号",
    pile_no:"桩号",
    pile_type:"类型",
    pile_type_battery:"电池桩",
    pile_type_empty:"空桩",
    sp_battery:"电池仓(桩)",
    sp_empty:"空仓(桩)",
    sp_error:"错误仓(桩)",
    open_slot:"开仓",
    createWorkOrder:"新建工单",
    enable:"启用",
    slotRestart:"仓重启",
    sure_restart_slot:"号仓将重启分控板，清除故障，并启动充电器恢复充电。有故障需要保留的，可以将对应仓禁用，并输入备注，是否重启？",
    disable:"禁用",
    address:"设备地址",
    slot_loading:"仓信息加载中...",
    pile_loading:"桩信息加载中...",
    name:"设备名称",
    type:"设备类型",
    pattern:"设备型号",
    dev_id:"编号",
    holder:"所属用户",
    soc:"SOC",
    battery_soc:"电池SOC",
    error_no:"故障状态码",
    charge_count:"充电次数",
    status:"状态",
    operate_status:"运营状态",
    online_status:"在线状态",
    deposit:"押金",
    deposit2:"押金(元)",
    agent:"代理",
    prices:{
      charge:"充电单价",
      deposit:"寄存单价",
      fine:"不关仓罚金",
    },
    moneys:{
      charge:"充电金额",
      save:"保存金额",
      err_close:"错误关仓金额",
    },
    isrent:'是否可租',
    isreplaceable:'是否可还',
    free_deposit_time2:"免费寄存时间",
    free_deposit_time:"免费寄存时间",
    statuses:{
      all:"所有",
      charging:"充电中",
      full:"满电",
      normal:"未充电",
      enabled:"已启用",
      disabled:"已禁用",
      online:"在线",
      offline:"离线",
      enrent:'可租',
      unrent:'不可租',
      replaceable:'可还',
      unreplaceable:'不可还',
    },
    types:{
      all:"所有",
      ebox:"换电柜",
      cbox:"充电柜",
      pile:"充电桩",
      cpbox:"混充电柜",
    },
    no:"设备编号",
    holder:"持有人名称",
    power:"剩余电量",
    slot_id:'所属仓位',
    soh:"SOH",
    volt:"电压",
    batteryTrajectory:"电池轨迹",
    cabinetUpdateTime:"机柜更新时间",
    batteryUpdateTime:"电池更新时间",
    cell_count:"电芯数量",
    protect:"电池保护状态",
    input_current:"充电电流",
    charge_status:"充电状态",
    input_volt:"充电电压",
    inner_input_current:"内部实际电流",
    env_temp:"环境温度",
    cell_temp:"电芯温度",
    board_temp:"板卡温度",
    move_status:{
      name:'运动状态',
      move:'移动',
      still:'静止',
      storage:'存储',
      hibernate:'休眠',
    },
    control_status:{
      name:'控制状态',
      discharge:'放电状态',
      charge:'充电状态',
      load:'负载在位状态',
      charged:'充电在位状态',
      empty:'空载状态',
    },
    net_status:{
      name:'联网状态',
      offline:'离线',
      online:'在线',
    },
    use_status:{
      name:'使用状态',
      idle:'空闲',
      cabinet:'在机柜',
      rider:'在骑手',
      uncabinet:'未上机',
    },
    rate:'速度(km/h)',
    deadline:'截止',
    ebox:{
      minPower:'最低可换电量',
      keepEmptyNum:'保留空仓数',
      keepBatteryNum:'保留电池仓数',
      startChargingTime:'开始充电时间',
      endChargingTime:'结束充电时间',
      chargingTime:'充电时长',
      startChargingSOC:'开始充电SOC',
      faultCodes:'故障码',
      faultChineseName:'故障中文名',
      enabledState:'启用状态',
      switchStatus:'开关状态',
      replaceableBattery:'可换电池',
      disableBin:'禁用仓',
    },
    carPile:{
      type:'充电桩类型',
      maxPower:'最大功率(kw)',
      realTimePower2:'实时功率(kw)',
      totalGunNum:'总枪数',
      gunNo:'枪号',
      gunStatus:'枪状态',
      realtimePower:'实时电量',
      operatePara:'运行参数',
      billingMethod:'计费方式',
      siteId:'站点id',
      directCurrent:'直流',
      altCurrent:'交流',
      status:{
        idle:'空闲',
        notCharging:'已插枪未充电',
        charging:'充电中',
        noUploadGun:'充电结束未拔枪',
        appointProgress:'预约中',
        fault:'故障',
      },
      method:{
        time:'时长计费',
        grade:'按功率等级加时长计费',
        power:'电量计费',
      }
    },
  },
  // 实体卡
  card:{
    number:"卡号",
    deposit:'押金',
    bind_user:"绑定用户",    
    unbind_user:"解绑用户",    
    unbind:"解绑",    
    renewal:"续租",    
    accountType:"账号类型",    
    buyCombo:"购买套餐",    
    cashRece:"共收现金",    
    WeChat:"微信小程序",    
    bindWeChat:"绑定微信账号",    
    Automatic:"自动",    
    bind_package:"绑定套餐",    
    beforeExp:"当前套餐到期",    
    afterExp:"续费后套餐到期",    
    expire:"到期时间",
    month:'月',
    times:'次',
    currency:'元',
    please_input_account:'用户名',
    binded_user:'已绑定用户',
    bind_tips:'确定为该用户绑定[xx/xx]套餐吗?',
    bind_status_no:'未绑定'
  },
  openSlotsLit:{
      user_id:'用户ID',
      dev_id:'设备ID',
      open_slots_type:'开仓类型',
      order_no:'订单号',
      open_slots_info:'开仓信息',
      open_slots_result:'开仓结果',
      agent_code:'运营商编码',
      site_name:'站点名称',
      slot_no:'仓号',
      ins_battery_soc:'插入电池Soc',
      ins_battery_id:'插入电池Id',
      pull_out_battery_soc:'拔出电池Soc',
      pull_out_battery_id:'拔出电池Id',
      create_time:'创建时间',
      result:{
        all:'全部',
        success:'成功',
        fail:'失败'
      },
      types:{
        all:'全部类型',
        all_slot:'全部仓',
        all_empty_slot:'全部空仓',
        all_battery_slot:'全部电池仓',
        Specify_slot:'指定仓',
        full_charge_slot:'全部满电仓',
      },
      no_data:"无",
      export_details:'详情导出',
      order_export:'订单导出',
      loading:'加载中，请稍后',
      opening_information_table:'开仓信息表'
  },
  alertRecord:{
      OrderNum:'订单号',
      agent_code:'运营商编码',
      site_name:'站点名称',
      mac:'设备mac',
      dev_id:'设备id',
      phone_num_type:'主叫号码类型',
      phone_num_type_one:'公共',
      phone_num_type_two:'专号',
      dial_phone:'主叫号码',
      phones:'被叫号码',
      type:'故障类型',
      call_id:'呼叫id',
      status_code:'状态码',
      res:'结果',
      create_time:'创建时间',
      result:{
        all:'全部',
        success:'成功',
        fail:'失败'
      },
      alarm_information_table:'报警信息表'
  },
  activityManage:{
    title:'活动名称',
    time:'活动时间',
    start_time:'开始时间',
    end_time:'结束时间',
    type:'活动类型',
    activity_status:'活动状态',
    push_type:'推送方式',
    style:'活动样式',
    award_type:'奖励类型',
    award_num:'奖励数量',
    award_unit:'奖励单位',
    award_rule:'活动规则',
  },
  receive_list:{
    order_code:'订单编码',
    coupon_code:'优惠码',
    receive_id:'领取优惠券ID',
    receive_info:'领取优惠券信息',
    Distributer:'发券人',
    receive_id:'领取用户ID',
    user_info:'领取用户信息',
    receive_time:'领取时间',
    status:'领取状态',
    use_channel:'使用渠道',
    coupon_type:'优惠券类型',
    use_time:'使用时间',
    remark:'备注',
    coupon:'优惠券',
    face_value:'面额',
    face_num:'面额/剩余数量',
    num:'数量',
    remain_num:'剩余数量',
    user_type:'用户类型',
    user:'用户',
    channel:'渠道',
    withdraw:'撤回',
    statuses:{
      unreceive:'未领取',
      unuse:'未使用',
      used:'已使用',
      expired:'已过期',
      withdraw:'已撤回',
    },
    query:{
      current_status:'当前状态'
    },
    receive_phone:'领取人手机号',
    receive_channel:'领取渠道',
    phone_placeholder:`请输入领取人手机号,多个手机号请使用半角逗号进行分割如:
13700000000,13800000000
或使用空行进行分割如:
13700000000
13800000000
    `
  },
  coupon:{
    name:'优惠券名称',
    type:'类型',
    face_value:'面额',
    coupon_issuance_method:'发券方式',
    circulation:'发行量',
    delayedActivation:'延迟启用(天)',
    actual_circulation:'实际发行量',
    start_time:'开始时间',
    end_time:'截止时间',
    time:'起止时间',
    remark:'备注',
    status:'状态',
    rule:'限制规则',
    render:{
      type:{
        cash:'现金优惠券',
        time:'延长租期优惠券'
      },
      method:{
        new_user_register:'新用户注册',
        develop_new_user:'拉新用户',
        target:'定向手机发券',
        combo_aotu:'套餐自动发券',
        h5:'H5链接自动领取',
      }
    }
  },
  new_user:{
    validityPeriod:'邀请码有效期',
    VipRevenueModel:'VIP邀请人收益模式',
    n1:'VIP邀请人首次奖励',
    n1:'VIP邀请人后续次数',
    n1:'VIP邀请人后续奖励',
    n1:'普通邀请人收益模式',
    n1:'普通邀请人首次奖励',
    n1:'普通邀请人后续次数',
    n1:'通邀请人后续奖励',
    n1:'被邀请人赠送优惠券',
  },
  restriction_rule:{
    name:'限制名称',
    description:"限制描述",
    channel_restrict:"渠道限制",
    product_restrict:"产品限制",
    combo_restrict:"套餐限制",

  },
  temporary_record:{
    status:'状态',
    statuses:{
      failed:'暂存失败',
      staging:'暂存成功',
      cancel_fail:'解除暂存失败',
      cancel_success:'解除暂存成功'
    },
    start_time:'暂存开始时间',
    release_time:'暂存解除时间',
    days:'暂存天数',
    remark:'备注',
    extend_lease:'暂存延长租期',
    cabinet_sn:'暂存电柜SN号',
    Cangmen_no:'暂存电柜仓门编号',
    battery_sn:'暂存电池SN号',
    battery_soc:'暂存电池soc',
    remove_cabinet_sn:'解除暂存电柜SN号',
    remove_Cangmen_no:'解除暂存电柜仓门编号',
    remove_battery_sn:'解除暂存电池SN号',
    remove_battery_soc:'解除暂存电池soc',
    order:'订单号',
    change_time:'修改时间',
    expire_date:'套餐到期时间',
    remove_expire_date:'解除后到期时间',
  },
  login:{
    unlogin:"未登录",
    confirm_logout:"确定要退出登录吗?",
    wechat_scan_to_login:"微信扫一扫登录",
    btn_login:"登 录",
    verification_code:"验证码",
  },
  bigScreen:{
    title:'麦迪瑞运营平台',
    all:'所有',
    emotor:'车',
    ebox:'换电柜',
    cbox:'充电柜',
    online:'在线',
    offline:'离线',
    InDev:'在机柜',
    InMan:'在骑手',
    InIdle:'空闲',
    total_revenue:'总收益',
    daily_earnings:'今日收益',
    amount_received:'实收金额',
    today_income:'今日收款',
    refund_amount:'退款金额',
    today_refund:'今日退款',
    user_data:'用户数据',
    registered_users:'注册用户总数',
    valid_users:'有效用户总数',
    added_users:'新增用户总数',
    operation_data:'运营数据',
    BAT_swapping:'换电次数',
    total_BAT_number:'电池总数',
    total_vehicle:'车辆总数',
    success_rate_swapping:'换电成功率',
    battery_rentals:'出租电池数',
    vehicle_rentals:'出租车辆数',
    unit:'单位',
    not_active:'未激活',
    PCS:'个',
    digit:'位',
    total_battary:'电池总数',
    total_emotor:'车辆总数',
    total_ebox:'电柜仓数/仓总数',
    exBatId:'换电订单Id'

  },
  feedback:{
    time:"反馈时间",
    process:"处理进度",
    user:"反馈人员",
    content:"反馈内容",
    reply:"客服回复",
    error:"反馈失败",
    processing_time :"处理时间",
    statuses:{
      all:"所有状态",
      undeal:"未处理",
      done:"已完成",
      doing:"已跟进",
    },
    ph:{
      choose:"请选择",
      user:"请输入反馈人员",
      content:"请输入反馈内容",
    }
  },
  agentsSwitch:{
    SMS_verification:'短信验证',
    authentication:'身份验证',
    clearance_package:'退租清除套餐',
    coupon_function:'优惠券功能',
    temporary_storage:'暂存功能',
    new_feature:'拉新功能',
    package_bundled:'租换电套餐捆绑',
    voice_broadcasting:'语音播报功能',
    late_payment_deduction:'逾期扣费功能',
    first_package:'只能购买首次套餐',
    Enable:'启用',
    Disable:'禁用',
    save_settings:'保存设置'
  },
  statistics:{
    revenue_statistics:'收益统计',
    total_revenue:'总收益',
    yesterday_earnings:'昨天收益',
    yesterday_revenue_orders:'昨天收益订单数',
    today_earnings:'今天收益',
    today_revenue_orders:'今天收益订单数',
    yesterday_battery_replacement:'昨天换电订单数',
    today_battery_swapping:'今天换电订单数',
    income:'收益',
    number_of_orders:'订单数',
    cabinet_statistics:'机柜统计',
    on_line:'在线',
    offline:'离线',
    cabinets_with_abnormal_compartments:'有异常仓的柜',
    Cabinets_with_prohibited_storage:'有禁用仓的柜',
    non_battery_cabinets:'无可换电池柜数',
    empty_containers:'无空仓柜数',
    total:'总数',
    average_revenue_of_cabinets:'机柜平均收益',
    battery_statistics:'电池统计',
    idle:'空闲',
    number_of_cabinets:'在柜数',
    in_the_rider:'在骑手',
    offline_battery:'离线电池',
    online_battery:'在线电池',
    user_statistics:'用户统计',
    renting_valid_package:'在租(有效套餐)',
    be_overdue:'逾期',
    terminate_the_lease:'退租',
    new_lease:'新租',
    renewal_of_lease:'续租',
    number_of_battery_swapping_users:'换电用户数量',
    order_statistics:'订单统计',
    cabinet_order_statistics:'机柜订单统计',
    battery_replacement_orders:'换电订单统计',
    number_of_battery_replacements:'换电数量',
    site_name:'站点名称',
    device_ID:'设备ID',
    user_id:'用户ID',
    user_nickname:'用户昵称',
    phone_number:'手机号',
    battery_replacements_num:'换电次数',
    failed_order_details:'失败订单详情',
    nickname:'昵称',
    number:'号码',
    equipment_number:'设备编号',
    failure_reason:'失败原因',
  },
  announcementList:{
    title:'标题',
    pop_window_position:'首页弹窗位置',
    start_time:'开始时间',
    end_time:'结束时间',
    above_screen:'屏幕上方',
    middle_screen:'屏幕中间',
    not_display_pop:'不显示弹窗',
    announcement_title:'公告标题',
    announcement_time:'公告时间',
    do_you_jump:'是否跳转',
    number_of_pop:'弹框次数',
    allow_no_reminders:'允许不在提醒',
    announcement_content:'公告内容',
    please_enter_content:'请输入公告内容',
    jump_new_customer:'跳转至拉新',
    jump_to_coupon:'跳转至优惠券'
  },
  overdue:{
    processing_status:'处理状态',
    all:'所有',
    already_overdue:'已逾期',
    overdue_lease:'逾期已续租',
    terminationlease:'逾期已退租',
    manual_end:'手动结束',
    completion_status:'完结状态',
    Unfinished:'未完结',
    Completed:'已完结',
    user_nickname:'用户昵称',
    user_mobile:'用户手机号',
    overdue_fees:'系统逾期费用',
    settlement_fees:'结算逾期费用',
    system_overdue_days:'系统逾期天数',
    settlement_overdue_days:'结算逾期天数',
    maximum_deduction:'最大扣款金额',
    standard_amount:'免扣费金额标准',
    actual_total_consumption:'实际消费总金额',
    package_expiration:'套餐到期时间',
    without_deduction:'免扣天数',
    payment_deduction:'逾期扣款价格',
    renewal_deduction:'续租扣除%',
    rent_deduction:'退租扣除%',
    purchase_frequency:'购买次数',
    operate:'操作',
    audit_settlement:'审核结算',
  },
  workOrder:{
    work_status:'工单状态',
    state_emergency:'紧急状态', 
    service_number:'客服号码',
    sales_number:'售后号码',
    batch_confirm:'批量确认',
    cabinet_ID:'机柜Id',
    title:'标题',
    content:'内容',
    order_number:'工单号',
    customer_service:'客服',
    after_sales:'售后',
    service_description:'客服描述',
    after_supplement:'售后补充',
    door_ID:'仓门Id',
    battery_ID:'电池Id',
    site_name:'站点名称',
    cabinet_address:'机柜地址',
    picture:'图片',
    view:'查看',
    update_ID:'更新用户Id',
    update_time:'更新时间',
    edit_workorder:'编辑工单',
    cabinet_compartment:'机柜仓号',
    workorder_title:'工单标题',
    descriptive_content:'描述内容',
    priority:'优先级',
    change_state:'更改状态',
    pending_orders:'待领单',
    received_order:'已领单',
    completed:'已完成',
    confirmed:'已确认',
    incomplete:'未完成',
    pending:'挂起',
    cancel:'作废',
    work_orderimage:'工单图片',
    general:'一般',
    emergency:'紧急',
    very_urgent:'非常紧急',
    
  },
  beOverdue:{
    overdue_setting:'逾期扣费设置',
    example_calculation:'扣费计算示例',
    overdue_txt1:'续租购买套餐后剩余天数：当前购买套餐天数-(逾期天数-免扣天数)*续租折扣',
    overdue_txt2:'示例(假设逾期10天，套餐30天)',
    overdue_txt3:'退租后剩余押金：押金-(逾期天数-免扣天数)*每日逾期罚金*退租折扣',
    overdue_txt4:'示例(假设押金300元，逾期10天)',
    free_days:'免扣天数',
    overdue_penalty:'逾期免罚金额标准',
    daily_fine:'每日逾期罚金',
    renewal_discount:'续租折扣',
    return_discount:'退租折扣',
    overdue_limit:'逾期扣费上限',
    overdue_txt5:'重要提示：(文本中的[time]代表还剩下几天逾期，[money]代表逾期费用，[day]代表套餐续期扣除天数,这是针对用户计算的动态数值，请不要进行修改，只修改文本或调换[time]位置即可。请注意编写的文案能搭配自己的逾期费用设置,下面左边是示例,右边是转化后的参考效果)',
    overdue_txt6:'套餐到期前弹框提醒文案',
    overdue_txt7:'转化后的文案参考效果',
    overdue_txt8:'逾期时提醒文案',
  },
  billingPolicy:{
    policy_name:'策略名称',
    billing_rules:'计费规则',
    rule_name:'规则名称',
    enter_rulename:'请输入规则名称',
    fee_settings:'费用设置',
    settings_txt1:'（峰、平、谷时段价格均为必填项。因各地电价不同，请根据当地实际电价填写。）',
    peak_period:'尖时段',
    peak_hours:'峰时段',
    normal_period:'平时段',
    valley_period:'谷时段',
    settings_txt2:'电费(元/度)',
    settings_txt3:'服务费(元/度)',
    charging_range:'充电价格档',
    settings_txt4:'（时间设置最小间隔为30分钟；最多可设置48个计费时段。）',
    bucket_type:'时段类型',
    start_time:'开始时间',
    end_time:'结束时间',
    settings_txt5:'(元/度)',
    electricity:'电费',
    service_charge:'服务费',
  },
  siteManager:{
    detailed_address:'详细地址',
    opening_hours:'营业时间',
    site_information:'站点信息',
    site_txt1:'推荐格式：全天或周一至周日 00:00-24:00',
    site_longitude:'站点经度',
    site_latitude:'站点纬度',
    parking_type:'停车费类型',
    parking_information:'停车收费信息',
    siteManager_txt1:'推荐格式：XX 元/度或停车免费等等',
    operational_status:'运营状态',
    stop_business:'停业',
    at_camp:'在营',
    charging_station:'充电站类型',
    site_images:'站点图片',
    charging_price:'充电价格',
    time_slot:'时间段',
    parking_fee:'停车收费',
    free_parking:'停车免费',
    free_time:'限时免费',
    charging_free:'充电限免',
    public_stations:'公共充电站',
    commercial_stations:'商业充电站',
    residential_station:'居住区充电站',
    highway_station:'高速公路充电站',
    smart_station:'智能充电站',
  },
  withdrawFunds:{
    revenue_status:'收益状态',
    revenue_model:'收益模式',
    mobile_phone:'收益人手机号',
    mobile_phone2:'被邀请人手机',
    system_status:'系统转账状态',
    batch_withdrawal:'批量提现',
    Inviter:'邀请人',
    Invitee:'被邀请人',
    user_ID:'收益人用户ID',
    name_beneficiary:'收益人姓名',
    invited_ID:'被邀请人Id',
    name_invitee:'被邀请人姓名',
    amount_invitee:'被邀请人收益金额',
    new_order:'拉新订单号',
    withdrawal_method:'提现方式',
    evidence_income:'收入证据',
    operator_ID:'操作人Id',
    notes:'备注',
    withdrawal_date:'可提现日期',
    system_time:'系统转账时间',
    cash_value:'现金值',
    coupons:'优惠券',
    transfer_pictures:'转账图片',
    system_transfer:'系统转账失败提醒',
  }
}
