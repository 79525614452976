<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("withdrawFunds.revenue_status") }}</span>
          <el-select class="inp" size="small" v-model="formData.valstatus" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("withdrawFunds.revenue_model") }}</span>
          <el-select class="inp" size="small" v-model="formData.IncomeMode" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="item in render.IncomeMode2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.agentCode"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t("withdrawFunds.mobile_phone") }}</span>
          <el-input class="inp" size="small" v-model="formData.valphone"
            :placeholder="$t('withdrawFunds.mobile_phone')"></el-input>
        </div>

        <div class="item">
          <span class="label">{{ $t('withdrawFunds.mobile_phone2') }}</span>
          <el-input class="inp" size="small" v-model="formData.InvatedPhoneNum"
            :placeholder="$t('withdrawFunds.mobile_phone2')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('withdrawFunds.system_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.transferStatus" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="(item, index) in render.TransferStatus" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.clear" class="search" type="info">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.exportAllExcel(0)" class="btn-export" type="info">{{ $t('g.export') }}</el-button>
        <el-button @click="func.batchDeal" class="btn-export" type="primary">{{ $t('withdrawFunds.batch_withdrawal')
          }}</el-button>
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" @row-click="func.showDetails" :stripe="true" style="width: 100%"
        class="el-table" @selection-change="func.handleSelectionChange">
        <el-table-column type="selection" width="55px" />
        <el-table-column prop="Id" label="Id" width="70px" fixed>
        </el-table-column>
        <el-table-column prop="OrderNum" :label="$t('order.no')" width="190px" fixed :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="User.AgentCode" label="运营商编码" width="100px" v-if="!isOperator()">
        </el-table-column>
        <el-table-column prop="State" label="收益状态" width="140px">
          <template #default="scope">
            <el-tag :class="scope.row.State == 3 ? 'tag-success' : 'tag-warning'">{{
              render.statuses[scope.row.State].label }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="TransferStatus" label="系统转账状态" width="110px">
          <template #default="scope">
            <el-tag :class="scope.row.TransferStatus == 3 ? 'tag-success' : 'tag-warning'">{{
              render.TransferStatus[scope.row.TransferStatus] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="IncomeMethod" label="提现方式" width="100px">
          <template #default="scope">
            {{ render.IncomeMethod[scope.row.IncomeMethod].label }}
          </template>
        </el-table-column>

        <el-table-column prop="UserId" label="收益人用户ID" width="110px">
        </el-table-column>
        <el-table-column prop="User.Mobile" label="收益人手机号码" width="130px">
        </el-table-column>
        <el-table-column prop="User.IdName" label="收益人姓名" width="100px">
        </el-table-column>
        <el-table-column prop="IncomeMode" label="收益模式" width="100px">
          <template #default="scope">
            {{ render.IncomeMode[scope.row.IncomeMode].label }}
          </template>
        </el-table-column>
        <el-table-column label="收益次数" width="100px">
          <template #default="scope">
            {{ render.InTimes[scope.row.InTimes].label }}
          </template>
        </el-table-column>
        <el-table-column prop="IncomeValue" label="收益金额" width="100px">
        </el-table-column>
        <el-table-column prop="InvitedUserId" label="被邀请人Id" width="100px">
        </el-table-column>
        <el-table-column prop="NewUser.Mobile" label="被邀请人号码" width="130px">
        </el-table-column>
        <el-table-column prop="NewUser.IdName" label="被邀请人姓名" width="120px">
        </el-table-column>
        <el-table-column prop="InvitedIncomeValue" label="被邀请人收益金额" width="140px">
        </el-table-column>
        <el-table-column prop="UserId" label="用户类型" width="110px">
          <template #default="scope">
            {{ scope.row.InvitedUserId ? '邀请人' : '被邀请人' }}
          </template>
        </el-table-column>
        <el-table-column prop="PullOrderNum" label="拉新订单号" width="140px">
        </el-table-column>
        <el-table-column prop="IncomeCertFile" label="收入证据" width="100px" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="操作人Id" width="100px">
          <template #default="scope">
            {{ scope.row.EditUserId != 0 ? scope.row.EditUserId : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="ReMark" label="备注" width="100px">
        </el-table-column>

        <el-table-column prop="NeedIncomeTime" label="可提现日期" width="180px">
        </el-table-column>

        <el-table-column prop="CreatedAtStr" label="开始时间" width="180px">
        </el-table-column>
        <el-table-column prop="UpdatedAtStr" label="结束时间" width="180px">
        </el-table-column>
        <el-table-column :width="86" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <!-- <el-tag size="large" type="success" @click="func.edit(scope.row)"
              v-if="scope.row.State != 3 && scope.row.State != 4">编辑</el-tag> -->

            <el-button @click="func.edit(scope.row)" v-if="scope.row.State != 3 && scope.row.State != 4"
              class="btn-edit" type="info" style="margin-left: 10px;">{{
                $t('g.edit')
              }}</el-button>
            <!-- <el-tag size="large" type="success" v-if="scope.row.State == 3 &&scope.row.IncomeMode == 0 && scope.row.TransferStatus != 0 && scope.row.TransferStatus != 3" @click="func.query(scope.row)"
              >查询状态</el-tag> -->
          </template>

        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" style="width:80%">
      <el-descriptions border align-center>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.no')">{{ formData.curData.OrderNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.agent_code')" v-if="isAdmin()">{{
          formData.curData.User?.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.revenue_status')"> <el-tag
            :class="formData.curData.State == 3 ? 'tag-success' : 'tag-warning'">{{
              render.statuses[formData.curData.State].label }}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.system_status')">
          <el-tag :class="formData.curData.TransferStatus == 3 ? 'tag-success' : 'tag-warning'">{{
            render.TransferStatus[formData.curData.TransferStatus] }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.user_type')">{{ formData.curData.InvitedUserId ?
          $t('withdrawFunds.Inviter') : $t('withdrawFunds.Invitee') }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.user_ID')">{{ formData.curData.UserId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.mobile_phone')">{{
          formData.curData.User?.Mobile }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.name_beneficiary')">{{
          formData.curData.User?.IdName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.revenue_model')"> {{
          render.IncomeMode[formData.curData.IncomeMode].label }}</el-descriptions-item> <el-descriptions-item
          :label="$t('home.income')">{{
            formData.curData.IncomeValue }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.invited_ID')">{{
          formData.curData.InvitedUserId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.mobile_phone2')">{{
          formData.curData.NewUser?.Mobile }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.name_invitee')">{{
          formData.curData.NewUser?.IdName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.amount_invitee')">{{
          formData.curData.InvitedIncomeValue }}</el-descriptions-item> <el-descriptions-item
          :label="$t('withdrawFunds.new_order')">{{
            formData.curData.PullOrderNum }}</el-descriptions-item> <el-descriptions-item
          :label="$t('withdrawFunds.withdrawal_method')">{{
            render.IncomeMethod[formData.curData.IncomeMethod].label }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.evidence_income')">
          <el-image style="width:200px;height: 200px;" v-if="formData.curData.IncomeCertFile"
            :src="formData.curData.IncomeCertFileBase" :preview-src-list="[formData.curData.IncomeCertFileBase]" />
        </el-descriptions-item> <el-descriptions-item :label="$t('withdrawFunds.operator_ID')">{{
          formData.curData.EditUserId != 0 ? formData.curData.EditUserId : '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.notes')">{{
          formData.curData.ReMark }}</el-descriptions-item>

        <el-descriptions-item :label="$t('withdrawFunds.withdrawal_date')">{{
          formData.curData.NeedIncomeTime }}</el-descriptions-item>
        <el-descriptions-item :label="$t('withdrawFunds.system_time')">{{
          formData.curData.UpdateTimeStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('activityManage.start_time')">{{
          formData.curData.CreatedAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('activityManage.end_time')">{{
          formData.curData.UpdatedAtStr }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog :title="$t('g.edit')" v-model="formData.dialogVisible" class="new-el-dialog" center align-center>
      <div class="prompt">{{ $t('activityManage.revenue_model') }}：{{ formData.curData.IncomeMode == 0 ?
        $t('activityManage.cash_value') : $t('activityManage.coupons') }} </div>
      <div class="dailog-content">
        <div class="activity-content">
          <el-form label-width="120px" class="e-form" :model="formData" ref="formDom">
            <el-form-item :label="$t('activityManage.revenue_status')" width="350px">
              <el-select class="inp" size="small" v-model="formData.curData.State" :placeholder="$t('g.choose')"
                @change="func.withdrawal_method(formData.curData.IncomeMode)">
                <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('activityManage.withdrawal_method')" width="350px">
              <el-select class="inp" size="small" v-model="formData.curData.IncomeMethod" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.IncomeMethod" :key="item.value" :label="item.label" :value="item.value"
                  :disabled="item.disabled">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('activityManage.transfer_pictures')" width="350px">
              <el-upload class="avatar-uploader" name="Img" action="/admin/pullgainorder/imgsUp" :multiple="false"
                :show-file-list="false" method="post"
                :data="{ 'id': 'ImgBox', 'DevId': formData.curData.DevId, 'ImgType': 0 }"
                :headers="{ Authorization: token }" :on-error="func.onUploadError" :on-success="func.onUploadSuccess"
                :before-upload="func.onUploadBefore" :http-request="(params) => func.onUpload(params)">
                <img v-if="formData.curData.IncomeCertFile" :src="formData.curData.IncomeCertFileBase" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">{{ $t('cancel') }}</el-button>
          <el-button type="primary" @click="func.updateData()">
            {{ $t('confirm') }}
          </el-button>
        </span>
      </template>
      <div class="prompt" v-if="formData?.errTxt?.length > 0" style="margin-top: 10px;color: red;">
        {{ $t('activityManage.system_transfer') }}：{{
          render.info_prompt[formData.errTxt] }}</div>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, computed, ref } from 'vue'
import activityStyle from '@/components/activityMode/activityStyle.vue'
import i18n from '@/data/i18n/index'
import { toDay, toDay3, toCounterTime } from '@/common/func'
import { ElMessage, ElMessageBox, ElLoading, dayjs } from 'element-plus'
import axios from "axios"
import cfg from '@/common/conf'
import { user, isOperator, isAdmin } from '@/data/token'
import { shortcuts, operators, operatorList, operatorCascader, pile, combo, cascade_options_direct } from '@/data/default'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'withdrawFunds',
  components: {
    activityStyle
  },
  setup() {
    const t = i18n.global.t

    const render = reactive({
      combo,
      pile,
      operators,
      operatorList,
      operatorCascader,
      shortcuts,
      statuses: [
        {
          label: '余额已到账',
          value: 0
        },
        {
          label: '现金提现审核中',
          value: 1
        },
        {
          label: '现金提现中',
          value: 2
        },
        {
          label: '现金已提现',
          value: 3
        },
        {
          label: '作废',
          value: 4
        },
        {
          label: '处理超时',
          value: 5
        },
      ],
      IncomeMode: [
        {
          label: '现金值',
          value: 0
        },
        {
          label: '现金百分百',
          value: 1
        },
        {
          label: '优惠券',
          value: 2
        }
      ],
      IncomeMode2: [
        {
          label: '现金值',
          value: 0
        },
        {
          label: '优惠券',
          value: 1
        }
      ],
      IncomeMethod: [
        {
          label: '系统转账',
          value: 0,
          disabled: true
        },
        {
          label: '人工转账',
          value: 1,
          disabled: false
        },
      ],

      InTimes: [
        {
          label: '首次',
          value: 0,
        },
        {
          label: '后续',
          value: 1,
        },
      ],
      TransferStatus: [
        "未转账",
        "待付款确认",
        "受理转账中",
        "转账成功",
        "转账失败",
      ],
      info_prompt: {
        'ACCOUNT_FROZEN': '该用户账户被冻结',
        'REAL_NAME_CHECK_FAIL': '收款人未实名认证，需要用户完成微信实名认证',
        'NAME_NOT_CORRECT': '收款人姓名校验不通过，请核实信息',
        'OPENID_INVALID': 'Openid格式错误或者不属于商家公众账号',
        'TRANSFER_QUOTA_EXCEED': '超过用户单笔收款额度，核实产品设置是否准确',
        'DAY_RECEIVED_QUOTA_EXCEED': '超过用户单日收款额度，核实产品设置是否准确',
        'MONTH_RECEIVED_QUOTA_EXCEED': '超过用户单月收款额度，核实产品设置是否准确',
        'DAY_RECEIVED_COUNT_EXCEED': '超过用户单日收款次数，核实产品设置是否准确',
        'PRODUCT_AUTH_CHECK_FAIL': '未开通该权限或权限被冻结，请核实产品权限状态',
        'OVERDUE_CLOSE': '超过系统重试期，系统自动关闭',
        'ID_CARD_NOT_CORRECT': '收款人身份证校验不通过，请核实信息',
        'ACCOUNT_NOT_EXIST': '该用户账户不存在',
        'TRANSFER_RISK': '该笔转账可能存在风险，已被微信拦截',
        'OTHER_FAIL_REASON_TYPE': '其它失败原因',
        'REALNAME_ACCOUNT_RECEIVED_QUOTA_EXCEED': '用户账户收款受限，请引导用户在微信支付查看详情',
        'RECEIVE_ACCOUNT_NOT_PERMMIT': '未配置该用户为转账收款人，请在产品设置中调整，添加该用户为收款人',
        'PAYEE_ACCOUNT_ABNORMAL': '用户账户收款异常，请联系用户完善其在微信支付的身份信息以继续收款',
        'PAYER_ACCOUNT_ABNORMAL': '商户账户付款受限，可前往商户平台获取解除功能限制指引',
        'TRANSFER_SCENE_UNAVAILABLE': '该转账场景暂不可用，请确认转账场景ID是否正确',
        'TRANSFER_SCENE_INVALID': '你尚未获取该转账场景，请确认转账场景ID是否正确',
        'TRANSFER_REMARK_SET_FAIL': '转账备注设置失败， 请调整后重新再试',
        'RECEIVE_ACCOUNT_NOT_CONFIGURE': '请前往商户平台-商家转账到零钱-前往功能-转账场景中添加',
        'BLOCK_B2C_USERLIMITAMOUNT_BSRULE_MONTH': '超出用户单月转账收款20w限额，本月不支持继续向该用户付款',
        'BLOCK_B2C_USERLIMITAMOUNT_MONTH': '用户账户存在风险收款受限，本月不支持继续向该用户付款',
        'MERCHANT_REJECT': '商户员工（转账验密人）已驳回转账',
        'MERCHANT_NOT_CONFIRM': '商户员工（转账验密人）超时未验密',
        'SYSTEM_ERROR': '系统错误',
        'NOT_ENOUGH': '账户金额不足',
        'NOT_FOUND': '记录不存在',
      },
      cascade_options_direct
    })

    const formDom = ref(null)
    const formData = reactive({
      tableData: [],
      title: '',
      operateType: 1,//操作类型 1 新增 2 编辑
      id: 0,
      userType: 0,
      dialogVisible: false,
      dialogDisplayVisible: false,
      total: 1,
      curPage: 1,
      pageSize: 10,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      batchDealIds: '',//批量提现ids 1,2,3
      batchDealAmount: 0,//批量提现总金额
      errTxt: '',
      transferStatus: ''
    })
    const rules = reactive({
    })
    const func = {
      async exportAllExcel(type = 1) {
        let params = {
        }
        if (formData.valphone) {
          params.PhoneNum = formData.valphone.trim()
        }
        if (formData.valstatus || formData.valstatus == 0) {
          params.Status = formData.valstatus
        }
        if (formData.IncomeMode || formData.IncomeMode == 0) {
          params.IncomeMode = formData.IncomeMode
        }
        if (formData.agentCode) {
          params.AgentCode = formData.agentCode
        }

        if (formData.valTime) {
          params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
        }
        params.getAll = 1
        let loading = ''
        loading = ElLoading.service({
          lock: true,
          text: t('openSlotsLit.loading'),
          background: 'rgba(0, 0, 0, 0.7)',
        })
        let res = await axios.get(`/admin/pullgainorder/get`, {
          params
        });
        let excleData = [[]];
        let wscols = [];
        excleData = [[
          'ID',
          t('openSlotsLit.agent_code'),
          '收益人姓名',
          '收益人手机',
          '收益人用户Id',
          '收益模式',
          '收益次数',
          '收益金额',
          '被邀请人Id',
          '被邀请人姓名',
          '被邀请人号码',
          '被邀请人收益金额',
          '收益状态',
          '创建时间',
          '更新时间',
        ]];
        wscols = [    // 每列不同宽度px
          { wch: 8 },
          { wch: 10 },
          { wch: 10 },
          { wch: 12 },
          { wch: 12 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 12 },
          { wch: 10 },
          { wch: 12 },
          { wch: 12 },
          { wch: 8 },
          { wch: 22 },
          { wch: 22 },
        ];
        if (res && res.List) {
          for (let item of res.List) {
            let arr = [];

            arr = [
              item.Id,
              item.User?.AgentCode,
              item.User?.IdName,
              item.User?.Mobile,
              item.UserId,
              render.IncomeMode[item.IncomeMode].label,
              item.InTimes == 0 ? '首次' : '后续',
              item.IncomeValue,
              item.InvitedUserId,
              item.NewUser?.IdName,
              item.NewUser?.Mobile,
              item.InvitedIncomeValue,
              render.statuses[item.State].label,
              toDay(item.CreatedAt),
              toDay(item.UpdatedAt),
            ]
            excleData.push(arr)
          }
        }

        const worksheet = XLSX.utils.aoa_to_sheet(excleData);
        worksheet["!cols"] = wscols;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        loading.close()
        XLSX.writeFile(workbook, '提现资料' + '.xlsx');
      },
      batchDeal() {

        if (!formData.valphone || formData.valstatus != 0 || formData.IncomeMode != 0) {
          return ElMessage({
            type: 'error',
            message: '请按号码、现金和余额已到账筛选！'
          })
        }
        if (formData.batchDealAmount <= 0) {
          return ElMessage({
            type: 'error',
            message: '请选择订单！'
          })
        }
        ElMessageBox.confirm('提现总金额为' + formData.batchDealAmount + '元,确认要提现吗？', {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(async () => {
          let loading = ''
          try {
            loading = ElLoading.service({
              lock: true,
              text: t('openSlotsLit.loading'),
              background: 'rgba(0, 0, 0, 0.7)',
            })
            let res = await axios.post("/admin/pullgainorder/submitBatchEditPayed", {
              Ids: formData.batchDealIds,
            })
            setTimeout(() => {
              loading.close()
            }, 200)
            if (res == 'Ok') {
              func.loadData()
              return ElMessage({
                type: 'success',
                message: t('g.success')
              })
            }

          } catch (e) {
            setTimeout(() => {
              loading.close()
            }, 200)
            console.log("error", e)
          }
        })
      },
      handleSelectionChange(rows) {
        formData.batchDealIds = ''
        formData.batchDealAmount = 0
        console.log(283348);
        let arr = []
        let sum = 0
        console.log(JSON.stringify(rows), 2380);
        for (let item of rows) {
          arr.push(item.Id)
          sum += item.IncomeValue
        }
        if (arr.length > 0) {
          formData.batchDealIds = arr.join(',')
          formData.batchDealAmount = sum
        }
        console.log(JSON.stringify(formData.batchDealIds));
        console.log(JSON.stringify(formData.batchDealAmount));

      },
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.curData = row;
        if (formData.curData.IncomeCertFile) {
          func.getImage()
        }
        formData.dialogDisplayVisible = true;
      },
      search() {
        func.loadData()
      },
      async updateData() {
        let res
        try {
          if (formData.curData.IncomeMethod == 0) {
            let params = {
              orderNum: formData.curData.OrderNum,
            }
            //系统转账
            res = await axios.post('/admin/gaintransfer/transfer', params)
          } else {
            let params = {
              Id: formData.curData.Id,
              State: formData.curData.State,
              IncomeMethod: formData.curData.IncomeMethod,
            }
            res = await axios.post('/admin/pullgainorder/edit', params)
          }
          if (res) {
            formData.dialogVisible = false
            func.loadData(1, false)
          }

        } catch {
          formData.dialogVisible = false
          func.loadData(1, false)
        }
      },
      async onUpload(data) {
        let fd = new FormData();
        let headers = {
          'Content-Type': 'multipart/form-data'
        }
        fd.append('Img', data.file)
        fd.append('OrderNum', formData.curData.OrderNum)
        let res = await axios.post('/admin/pullgainorder/imgsUp', fd, { headers })
        func.getImage()
      },
      async getImage() {
        if (!formData.curData.OrderNum) {
          return;
        }
        let res = await axios.post('/admin/pullgainorder/imgsGet', {
          OrderNum: formData.curData.OrderNum,
        })

        if (res) {
          if (res.Img) {
            formData.curData.IncomeCertFileBase = 'data:image/bmp;base64,' + res.Img;
          }
        }
      },
      onUploadBefore() {
        console.log("onUploadBefore")
      },
      onUploadError() {
        ElMessage({
          type: 'error',
          message: t('g.upload_failed')
        })
      },
      onUploadSuccess(res) {
        return
      },
      async query(OrderNum, txt) {
        //console.log(row.Id,'dadasdasda')
        let params = {
          orderNum: OrderNum
        }
        let res = await axios.get('/admin/gaintransfer/transfermerchantdetail', { params })
        if (txt) {
          formData.errTxt = res.FailReason
        } else {
          formData.errTxt = ''
        }


        // TransferStatus
        // if(res.FailReason == ''){
        //   ElMessage({
        //     message: '转账成功',
        //     type: 'success',
        //   })
        // }else{
        //   ElMessage.error(res.FailReason)
        // }
        // func.loadData()
      },
      async edit(row, type = 0) {
        //formData.curData = row
        formData.curData = Object.assign({}, row)
        formData.curData.IncomeMethod = 1
        formData.errTxt = ''
        if (formData.curData.TransferStatus == 4) {
          func.query(formData.curData.OrderNum, true)
        }
        render.IncomeMethod[0].disabled = true
        if (formData.curData.IncomeCertFile) {
          func.getImage()
        }
        formData.dialogVisible = true;
      },
      clear() {
        formData.valphone = '';
        formData.agentCode = '';
        formData.valstatus = '';
        formData.valTime = '';
        func.loadData()
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      withdrawal_method(IncomeMode) {
        if (formData.curData.State == 3 && IncomeMode == 0) {
          render.IncomeMethod[0].disabled = false
        } else {
          formData.curData.IncomeMethod = 1
          render.IncomeMethod[0].disabled = true
        }
      },
      date_conversion(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate
      },
      async loadData(page = 1, type = true) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valphone) {
            params.PhoneNum = formData.valphone.trim()
          } formData.InvatedPhoneNum

          if (formData.InvatedPhoneNum) {
            params.InvatedPhoneNum = formData.InvatedPhoneNum.trim()
          }
          if (formData.valstatus || formData.valstatus == 0) {
            params.Status = formData.valstatus
          }
          if (formData.IncomeMode || formData.IncomeMode == 0) {
            params.IncomeMode = formData.IncomeMode
          }
          if (formData.agentCode) {
            params.AgentCode = formData.agentCode
          }

          if (formData.transferStatus || formData.transferStatus == 0) {
            params.transferStatus = formData.transferStatus
          }

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)

          }

          let res = await axios.get('/admin/pullgainorder/get', {
            params
          })
          formData.total = res.Total
          let num = 0

          const isoString = "2024-10-28T11:14:05+08:00";


          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.UpdatedAtStr = toDay(item.UpdatedAt)
            if (item.UpdateTime) {
              item.UpdateTimeStr = func.date_conversion(item.UpdateTime)
            }
            if ((item.TransferStatus == 1 || item.TransferStatus == 2) && type == true) {
              setTimeout(() => {
                func.query(item.OrderNum)
                num++
              }, num * 1000)
            }
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize || 10

        } catch (e) {
          console.log("loadData", e)
        }
      },
    }
    onMounted(() => {

      func.loadData()
    })
    return {
      func,
      formData,
      render,
      token: user.token,
      uploadUrl: cfg.uploadUrl,
      isOperator,
      isAdmin,
      rules,
      formDom,
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;

        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    >.pagination {
      text-align: right;
      margin-top: 1.25rem;
    }

  }

  .new-el-dialog {
    .dailog-content {
      display: flex;
      // width:500px;
    }

    .activity-content {
      flex: 2
    }

    .activity-style {
      flex: 1;
    }

    .e-form {
      display: flex;
      flex-wrap: wrap;

      .inp {
        width: 192px;
      }
    }

  }

  :deep(.m-dialog) {
    // max-width: 80rem;
    // margin-top: 0;
  }

  .avatar-uploader {
    :deep(.el-upload) {
      border: 1px dashed #d9d9d9;
      border-radius: 0.375rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409eff;
      }
    }
  }

  .avatar-uploader-icon {
    font-size: 1.75rem;
    color: #8c939d;
    width: 11rem;
    height: 9rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar {
    width: 11rem;
    height: 9rem;
    display: block;
    object-fit: cover;
  }
}

.prompt {
  margin-left: 52px;
  margin-bottom: 10px;
}
</style>