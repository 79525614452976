<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props2" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t('workOrder.work_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valState" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="item in render.StateMap" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('workOrder.state_emergency') }}</span>
          <el-select class="inp" size="small" v-model="formData.valUrgentState" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="item in render.UrgentStateMap" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('workOrder.service_number') }}</span>
          <el-input class="inp" size="small" v-model="formData.valAssignMobile"
            :placeholder="$t('workOrder.service_number')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('workOrder.sales_number') }}</span>
          <el-input class="inp" size="small" v-model="formData.valAssignedMobile"
            :placeholder="$t('workOrder.sales_number')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('workOrder.cabinet_ID') }}</span>
          <el-input class="inp" size="small" v-model="formData.valEboxId"
            :placeholder="$t('workOrder.cabinet_ID')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('workOrder.title') }}</span>
          <el-input class="inp" size="small" v-model="formData.valTitle"
            :placeholder="$t('workOrder.title')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('workOrder.content') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderDesc"
            :placeholder="$t('workOrder.content')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.batchDeal" class="btn-export" type="primary">{{ $t('workOrder.batch_confirm')
          }}</el-button>
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" :stripe="true" @row-click="func.showDetails" style="width: 100%"
        class="el-table" @selection-change="func.handleSelectionChange">
        <el-table-column type="selection" width="55px" />
        <el-table-column prop="Id" label="Id" width="50px" fixed>
        </el-table-column>
        <el-table-column prop="OrderSn" :label="$t('workOrder.order_number')" width="120px">
        </el-table-column>
        <el-table-column prop="AgentInfo.NamePath" :label="$t('g.operator_ownership')" width="160px">
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('workOrder.cabinet_ID')" width="130px">
        </el-table-column>
        <el-table-column :label="$t('workOrder.customer_service')" width="100px">
          <template #default="scope">
            {{ scope.row.AssignUserInfo?.IdName || scope.row.AssignUserInfo?.Nickname }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('workOrder.after_sales')" width="100px">
          <template #default="scope">
            {{ scope.row.AssignedUserInfo?.IdName || scope.row.AssignedUserInfo?.Nickname }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('workOrder.work_status')" width="100px">
          <template #default="scope">
            <el-tag :type="scope.row.State == 5 ? 'success' : (scope.row.State == 3 ? 'error' : '')">
              {{ render.State[scope.row.State] }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('workOrder.state_emergency')" width="100px">
          <template #default="scope">
            <el-tag :type="scope.row.UrgentState == 2 ? 'error' : ''">
              {{ render.UrgentState[scope.row.UrgentState]
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Title" :label="$t('workOrder.title')" width="180px">
        </el-table-column>
        <el-table-column prop="OrderDesc" :label="$t('workOrder.service_description')" width="180px">
        </el-table-column>
        <el-table-column prop="AssignedDesc" :label="$t('workOrder.after_supplement')" width="100px">
        </el-table-column>
        <el-table-column prop="SlotId" :label="$t('workOrder.door_ID')" width="100px">
        </el-table-column>
        <el-table-column prop="BatId" :label="$t('workOrder.battery_ID')" width="100px">
        </el-table-column>
        <el-table-column prop="CreateAtStr" :label="$t('g.create_at')" width="180px">
        </el-table-column>
        <el-table-column prop="Remark" :label="$t('coupon.remark')" width="160px">
        </el-table-column>
        <el-table-column :width="170" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <!-- <el-tag size="large" :type="(scope.row.State == 5 || scope.row.State == 6) ? 'info' : 'success'"
              @click="func.editOrder(scope.row)">{{
                $t('g.edit') }}</el-tag>
            <el-tag size="large" :type="(scope.row.State == 6 || scope.row.State == 0) ? 'warning' : 'info'"
              style="margin-left: 0.5rem;" @click="func.deleteOrder(scope.row.OrderSn, scope.row.State)">{{
                $t('g.delete') }}</el-tag> -->

            <el-button @click="func.deleteOrder(scope.row.OrderSn, scope.row.State)"
              :class="{ 'btn-delete': (scope.row.State == 6 || scope.row.State == 0) }" type="info">{{ $t('g.delete')
              }}</el-button>
            <el-button @click="func.editOrder(scope.row)"
              :class="{ 'btn-edit': !(scope.row.State == 5 || scope.row.State == 6) }" type="primary"
              style="margin-left: 10px;">{{
                $t('g.edit')
              }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" style="width:80%" align-center>
      <el-descriptions border>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.order_number')">{{ formData.curData.OrderSn
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')">{{ formData.curData.AgentCode }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.operator_ownership')">{{ formData.curData.AgentInfo?.NamePath
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.customer_service')">{{ formData.curData.AssignUserInfo?.Nickname
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.service_number')">{{ formData.curData.AssignUserInfo?.Mobile
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.after_sales')">{{ formData.curData.AssignedUserInfo?.Nickname
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.sales_number')">{{ formData.curData.AssignedUserInfo?.Mobile
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.title')">{{ formData.curData.Title }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.service_description')">{{ formData.curData.OrderDesc
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.after_supplement')">{{ formData.curData.AssignedDesc
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.work_status')">
          <el-tag :type="formData.curData.State == 5 ? 'success' : (formData.curData.State == 3 ? 'error' : '')">
            {{ render.State[formData.curData.State] }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.state_emergency')">
          <el-tag :type="formData.curData.UrgentState == 2 ? 'error' : ''">
            {{ render.UrgentState[formData.curData.UrgentState]
            }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.cabinet_ID')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.site_name')">{{ formData.curData.Ebox?.SiteName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.cabinet_address')">{{ formData.curData.Ebox?.Address
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.door_ID')">{{ formData.curData.SlotId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.battery_ID')">{{ formData.curData.BatId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.picture')">
          <span v-if="formData.curData.IsUpImg" @click=func.checkWordOrderImage(formData.curData)
            style="color:#409eff">{{
              $t('workOrder.view') }}</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('coupon.remark')">{{ formData.curData.Remark }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.update_ID')">{{ formData.curData.UpdateUserId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('workOrder.update_time')">{{ formData.curData.UpdateAtStr
          }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog :title="$t('workOrder.edit_workorder')" v-model="formData.dialogEditVisible" width="60%">
      <el-form label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('workOrder.cabinet_ID')">
              <el-input v-model="formData.curData.DevId" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('workOrder.cabinet_compartment')">
              <el-input v-model="formData.curData.orderSlotId" disabled />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('workOrder.battery_ID')">
              <el-input v-model="formData.curData.orderBatId" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('workOrder.workorder_title')">
              <el-input v-model="formData.curData.orderTitle" :disabled="formData.curData.orderState !== 0" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('workOrder.descriptive_content')">
              <el-input :rows="3" type="textarea" v-model="formData.curData.orderContent"
                :disabled="formData.curData.orderState !== 0" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('order.remark')">
              <el-input :rows="3" type="textarea" v-model="formData.curData.orderRemark" />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row v-if="formData.curData.orderState == 2">
          <el-col :span="24">
            <el-form-item label="完成内容">
              <el-input :rows="4" type="textarea" v-model="formData.curData.orderAssignDesc" />
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('workOrder.customer_service')">
              <el-select class="inp" v-model="formData.curData.orderAssignUser" :placeholder="$t('g.choose')"
                :disabled="formData.curData.orderState !== 0">
                <el-option v-for="item in formData.orderAssignUserList" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('workOrder.after_sales')">
              <el-select class="inp" v-model="formData.curData.orderSale" :placeholder="$t('g.choose')"
                :disabled="formData.curData.orderState !== 0">
                <el-option v-for="item in formData.orderSaleList" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="12">
            <el-form-item :label="$t('workOrder.priority')">
              <el-select class="inp" v-model="formData.curData.orderUrgentState" :placeholder="$t('g.choose')" disabled>
                <el-option v-for="item in render.UrgentStateMap" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('workOrder.change_state')">
              <el-radio-group v-model="formData.curData.orderState">
                <el-radio :value="0" :label="0"
                  v-if="formData.curData.rowState == 0 || formData.curData.rowState == 4">{{
                    $t('workOrder.pending_orders') }}</el-radio>
                <el-radio :value="1" :label="1" v-if="formData.curData.rowState == 1">{{ $t('workOrder.received_order')
                  }}</el-radio>
                <el-radio :value="2" :label="2"
                  v-if="formData.curData.rowState == 1 || formData.curData.rowState == 2">{{
                    $t('workOrder.completed') }}</el-radio>
                <el-radio :value="5" :label="5"
                  v-if="formData.curData.rowState == 2 || formData.curData.rowState == 3">{{
                    $t('workOrder.confirmed') }}</el-radio>
                <el-radio :value="3" :label="3"
                  v-if="formData.curData.rowState == 2 || formData.curData.rowState == 3">{{
                    $t('workOrder.incomplete') }}</el-radio>
                <el-radio :value="4" :label="4"
                  v-if="formData.curData.rowState == 0 || formData.curData.rowState == 1 || formData.curData.rowState == 4">{{
                    $t('workOrder.pending') }}</el-radio>
                <el-radio :value="6" :label="6"
                  v-if="formData.curData.rowState == 0 || formData.curData.rowState == 6">{{
                    $t('workOrder.cancel') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="当前状态">
              {{ render.State[formData.curData.orderState] }}
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>

        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogEditVisible = false">{{ $t('cancel') }}</el-button>
          <el-button type="primary" @click="func.editWorkOrderSubmit()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('workOrder.work_orderimage')" v-model="formData.dialogImageVisible" width="60%">
      <el-image :src="formData.WordOrderImgUrl" :preview-src-list="[formData.WordOrderImgUrl]"
        style="width:600px;height: 400px;" alt="图片缺失" fit="cover" />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogImageVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, computed, ref, watch, nextTick } from 'vue'
import activityStyle from '@/components/activityMode/activityStyle.vue'
import i18n from '@/data/i18n/index'
import { toDay, toDay3, toCounterTime } from '@/common/func'
import { ElMessage, ElMessageBox, ElLoading, dayjs } from 'element-plus'
import axios from "axios"
import { user, isOperator, isAdmin } from '@/data/token'
import { shortcuts, operators, operatorCascader, pile, combo, cascade_options_direct } from '@/data/default'
export default {
  name: 'restrictionRule',
  components: {
    activityStyle
  },
  setup() {
    const t = i18n.global.t
    const render = {
      combo,
      pile,
      operators,
      operatorCascader,
      cascade_options_direct,
      shortcuts,
      State: [t('workOrder.pending_orders'), t('workOrder.received_order'), t('workOrder.completed'), t('workOrder.incomplete'), t('workOrder.pending'), t('workOrder.confirmed'), t('workOrder.cancel')
      ],
      StateMap: [
        {
          label: t('workOrder.pending_orders'),
          value: 0
        },
        {
          label: t('workOrder.received_order'),
          value: 1
        },
        {
          label: t('workOrder.completed'),
          value: 2
        },
        {
          label: t('workOrder.incomplete'),
          value: 3
        },
        {
          label: t('workOrder.pending'),
          value: 4
        },
        {
          label: t('workOrder.confirmed'),
          value: 5
        },
        {
          label: t('workOrder.cancel'),
          value: 6
        },
      ],
      UrgentState: [t('workOrder.general'), t('workOrder.emergency'), t('workOrder.very_urgent')],
      UrgentStateMap: [
        {
          label: t('workOrder.general'),
          value: 0
        },
        {
          label: t('workOrder.emergency'),
          value: 1
        },
        {
          label: t('workOrder.very_urgent'),
          value: 2
        },
      ],

    }
    const formDom = ref(null)
    const formData = reactive({
      tableData: [],
      title: '',
      curData: {},
      operateType: 1,//操作类型 1 新增 2 编辑
      id: 0,
      name: '',
      type: 0,
      face_value: '',
      method: 0,
      circulation: '',
      comboList: [],
      valTime: '',
      remark: '',
      status: 0,
      orderSaleList: [],//工单售后列表
      batchDealIds: '',//批量处理id
      orderAssignUserList: [],
      dialogVisible: false,
      dialogDisplayVisible: false,
      dialogEditVisible: false,
      dialogImageVisible: false,
      checkWordOrderImage: false,
      WordOrderImgUrl: '',
      total: 1,
      curPage: 1,
      pageSize: 10,
      AddAbleUserTime: 0,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
    })
    const func = {
      async batchDeal() {
        if (!formData.batchDealIds) {
          ElMessage({
            type: 'warning',
            message: '请选择要批量处理的工单'
          })
          return
        }
        if (formData.valState != 2) {
          ElMessage({
            type: 'warning',
            message: '只能批量确认已完成的工单'
          })
          return
        }
        try {
          let res = await axios.post("/admin/workorder/batchconfirm", {
            IdStr: formData.batchDealIds,
          })
          if (res) {
            ElMessage({
              type: 'success',
              message: res,
            })
            func.loadData(formData.curPage)
          }
        } catch (error) {
          ElMessage({
            type: 'error',
            message: error
          })
        }
      },
      handleSelectionChange(rows) {
        formData.batchDealIds = ''
        let arr = []
        for (let item of rows) {
          arr.push(item.Id)
        }
        if (arr.length > 0) {
          formData.batchDealIds = arr.join(',')
        }
        console.log(JSON.stringify(formData.batchDealIds));

      },
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      search() {
        func.loadData()
      },
      pageChange(val) {
        func.loadData(val)
      },
      async checkWordOrderImage(row) {
        try {
          if (formData.checkWordOrderImage) {
            return
          }
          formData.checkWordOrderImage = true
          let res = await axios.post("/admin/workorder/imgsGet", {
            OrderSn: row.OrderSn,
            AgentCode: row.AgentCode,
          })
          // return;
          if (res) {
            formData.WordOrderImgUrl = 'data:image/bmp;base64,' + res[0];
            formData.dialogImageVisible = true
          }
          formData.checkWordOrderImage = false

        } catch { }
      },
      editOrder(row) {
        if (row.State == 5 || row.State == 6) {
          return
        }
        formData.curData.OrderSn = row.OrderSn
        formData.curData.DevId = row.DevId
        formData.curData.orderSlotId = row.SlotId
        formData.curData.orderBatId = row.BatId
        formData.curData.AgentCode = row.AgentCode
        formData.curData.orderUrgentState = row.UrgentState
        formData.curData.orderAssignDesc = row.AssignedDesc
        formData.curData.orderState = row.State
        formData.curData.rowState = row.State
        formData.curData.orderTitle = row.Title
        formData.curData.orderContent = row.OrderDesc
        formData.curData.orderAssignUser = row.AssignUserInfo?.Id
        formData.curData.orderSale = row.AssignedUserInfo?.Id
        formData.curData.orderRemark = row.Remark
        func.getAssignedUserList()
        func.getSales()
        formData.dialogEditVisible = true
      },
      async editWorkOrderSubmit() {
        let params = {}
        params.OrderSn = formData.curData.OrderSn
        params.DevId = formData.curData.DevId
        params.SlotId = formData.curData.orderSlotId
        params.BatId = formData.curData.orderBatId
        params.AssignDesc = formData.curData.orderAssignDesc
        params.UrgentState = formData.curData.orderUrgentState
        params.State = formData.curData.orderState
        params.Remark = formData.curData.orderRemark
        if (formData.curData.orderState == 0) {
          params.AssignedUserID = formData.curData.orderSale
          params.AssignUserID = formData.curData.orderAssignUser
          params.Title = formData.curData.orderTitle
          params.OrderDesc = formData.curData.orderContent
        }

        let res = await axios.post(`/admin/workorder/edit`,
          params
        )
        if (res) {
          formData.dialogEditVisible = false
          ElMessage({
            type: 'success',
            message: res,
          })
          func.loadData(formData.curPage)
        }
      },
      async getAssignedUserList() {
        let params = {}
        params.IsWorkOrder = 1
        params.getAll = 1
        params.agentcode = formData.curData.AgentCode
        let res = await axios.get(`/admin/user`, {
          params
        })
        if (res && res.List) {
          formData.orderAssignUserList = []
          res.List.forEach((item) => {
            formData.orderAssignUserList.push({
              value: item.Id,
              label: item.IdName || item.Nickname
            })
          })
        }
      },
      async getSales() {
        let params = {}
        params.userStatus = 8
        params.getAll = 1
        params.agentcode = formData.curData.AgentCode
        let res = await axios.get(`/admin/user`, {
          params
        })
        if (res && res.List) {
          formData.orderSaleList = []
          res.List.forEach((item) => {
            formData.orderSaleList.push({
              value: item.Id,
              label: item.IdName || item.Nickname
            })
          })
        }
      },
      async updateData() {
      },
      daysToTimestamp(days) {
        return days * 24 * 60 * 60;
      },
      timestampToDays(timestamp) {
        return timestamp / 86400;
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valTypes != -1) {
            params.openType = formData.valTypes
          }
          if (formData.valOperator) {
            params.agentCode = formData.valOperator
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          if (formData.valEboxId) {
            params.DevId = formData.valEboxId
          }
          params.State = formData.valState
          params.UrgentState = formData.valUrgentState
          if (formData.valAssignMobile) {
            params.AssignMobile = formData.valAssignMobile
          }
          if (formData.valAssignedMobile) {
            params.AssignedMobile = formData.valAssignedMobile
          }
          if (formData.valTitle) {
            params.Title = formData.valTitle
          }
          if (formData.valOrderDesc) {
            params.OrderDesc = formData.valOrderDesc
          }
          let res = await axios.get('/admin/workorder/get', {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {

            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = toDay(item.UpdateAt)


          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize

        } catch (e) {
          console.log("loadData", e)
        }
      },
      async deleteOrder(OrderSn, state) {
        if (!(state == 6 || state == 0)) {
          return
        }
        ElMessageBox.confirm(t('g.sure_delete'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {

            axios.post('/admin/workorder/delete', {
              OrderSn
            }).then((res) => {
              if (res == '操作成功') {
                ElMessage({
                  type: 'success',
                  message: t('g.success')
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: t("err_code.20005")
                })
              }
              func.loadData()
            })

          } catch (error) {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }

        })


      }
    }

    onMounted(() => {
      func.loadData()

    })
    return {
      func,
      formData,
      render,
      token: user.token,
      isOperator,
      isAdmin,
      formDom
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;

        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }


        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    >.pagination {
      text-align: right;
      margin-top: 1.25rem;
    }

  }

  .new-el-dialog {
    .dailog-content {
      display: flex;
      // width:500px;
    }

    .activity-content {
      flex: 2
    }

    .activity-style {
      flex: 1;
    }

    .e-form {
      display: flex;
      flex-wrap: wrap;
    }

  }

  :deep(.m-dialog) {
    // max-width: 80rem;
    // margin-top: 0;
  }
}
</style>